import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Petit bolet amb un capell d’1,5 a 3,5 cm de diàmetre, convex i ràpidament umbilicat en el centre. Té variabilitat de colors, del marró groguenc al verdós. Fibril·les aplicades en forma radial de color més fosc. Les làmines separades, són blanquinoses tornant rosades per les espores. Aquestes són anguloses, d’11-14 x 7,5-9 micres. El peu és llarg, prim, cilíndric i de color verdós.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      